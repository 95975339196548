import { createStore } from "vuex";
import  createPersistedState  from  'vuex-persistedstate'

export default createStore({
    plugins: [createPersistedState()],
    state: {
        role: "",
    },
    getters: {
        getUserRole(state) {
            return state.role;
        },
    },
    mutations: {
        SET_USER_ROLE(state, role) {
            state.role = role;
        },
    },
    actions: {
        async setUserRole(state, role) {
            this.commit("SET_USER_ROLE", role);
        },
    },
});
