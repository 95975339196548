import { createRouter, createWebHistory } from "vue-router";
import auth from "@/middleware/auth.js";

const routes = [
    {
        path: "/",
        name: "Home",
        component: () => import("@/views/security/login.vue"),
    },
    {
        path: "/login",
        name: "Login",
        component: () => import("@/views/security/login.vue"),
    },
    {
        path: "/logout",
        name: "Logout",
        component: () => import("@/components/login/Logout.js"),
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("@/views/dashboard/main.vue"),
        meta: {
            middleware: [auth],
        },
    },
    {
        path: "/orders",
        name: "Orders",
        component: () => import("@/views/order/main.vue"),
        meta: {
            middleware: [auth],
        },
    },
    {
        path: "/users",
        name: "Users",
        component: () => import("@/views/user/main.vue"),
        meta: {
            middleware: [auth],
        },
    },
    {
        path: "/vehicles",
        name: "Vehicles",
        component: () => import("@/views/vehicle/main.vue"),
        meta: {
            middleware: [auth],
        },
    },
    {
        path: "/FleetAssigment",
        name: "FleetAssigment",
        component: () => import("@/views/fleetAssigment/main.vue"),
        meta: {
            middleware: [auth],
        },
    },
    {
        path: "/routes",
        name: "Routes",
        component: () => import("@/views/routes/main.vue"),
        meta: {
            middleware: [auth],
        },
    },
    {
        path: "/RouteCreationStep1",
        name: "step1",
        component: () => import("@/views/routes/components/step1.vue"),
        meta: {
            middleware: [auth],
        },
    },
    {
        path: "/RouteCreationStep2",
        name: "step2",
        component: () => import("@/views/routes/components/step2.vue"),
        meta: {
            middleware: [auth],
        },
    },
    {
        path: "/RouteCreationStep3",
        name: "step3",
        component: () => import("@/views/routes/components/step3.vue"),
        meta: {
            middleware: [auth],
        },
    },
    {
        path: "/menu",
        name: "Menu",
        component: () =>
            import("@/components/dashboard/menu/MenuDashboard.vue"),
        meta: {
            middleware: [auth],
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];

    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        context.next(...parameters);

        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({ ...context, next: nextMiddleware });
    };
}

router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware)
            ? to.meta.middleware
            : [to.meta.middleware];

        const context = {
            from,
            next,
            router,
            to,
        };
        const nextMiddleware = nextFactory(context, middleware, 1);

        return middleware[0]({ ...context, next: nextMiddleware });
    }

    return next();
});

export default router;
