<template>
    <el-config-provider :locale="locale">
        <router-view />
    </el-config-provider>
</template>

<script>
import "@/assets/main.css";
import locale from "element-plus/lib/locale/lang/es";
import { ElConfigProvider } from "element-plus";
export default {
    name: "App",
    components: { ElConfigProvider },
    data() {
        return {
            locale,
        };
    },
};
</script>
